import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Holds and pushes the value of the currently active company
  private activeUser$: Subject<any>;

  // Computes the active company from local storage and store it as initial value
  constructor(@Inject(DOCUMENT) private document: any,) { 
    const initialActiveUser = JSON.parse(window.localStorage.getItem('LoggedInUserData'));
    this.activeUser$ = new BehaviorSubject<any>(initialActiveUser);
  }

  // Returns the observable (read-only) part of this subject
  getActiveUser$(): Observable<any> {
    return this.activeUser$.asObservable();
  }

  // Stores the new company value in local storage and pushes it to the subject
  setActiveUser(data: string) {
    window.localStorage.setItem('LoggedInUserData', data);
    this.activeUser$.next(JSON.parse(data));
  }

  setCompanyFavIcon(data: string) {
    this.document.getElementById('appFavicon').setAttribute('href', (data));
  }
}
