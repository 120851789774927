import { Component ,Inject,OnInit,ViewEncapsulation} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FuseConfirmDialogComponent implements OnInit
{
    customClass:any;
    public confirmMessage: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>
    )
    {
    }

   ngOnInit(){
    //   try{
    //      this.customClass = this._data.customClass; 
    //   }catch(err){
    //     console.log(err);
    //   }
    this.customClass =  this._data ? this._data.customClass: "";
   }

}
