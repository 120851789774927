import { stringify } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-payout-receipt',
  templateUrl: './payout-receipt.component.html',
  styleUrls: ['./payout-receipt.component.scss']
})
export class PayoutReceiptComponent implements OnInit {
  hostHeight: any;
  hostWidth: any;
  IsDialogMax: boolean = false;
  cambridgePaymentDetails:any;
  withdrawlAmount:any;
  currencyRate:any;
  serviceCharge:any;
  totalCost:any;
  depositAmount:any;
  dealDate:any;
  dealNumber:any;
  conversionCurrency:any;
  currency:any;
  depositCurrency:any;
  serviceChargeCurrency:any;
  estimateCostCurrency:any;
  EstimateCostAmount : any;
  SellCurrency : any;
  SellAmount : any;
  constructor(
    public matDialogRef: MatDialogRef<PayoutReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public _reportsService: ReportsService,
    private _toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.getCambridgePaymentOrder();   
  }
  onPrint(){
    window.print();
}
getCambridgePaymentOrder() {
  let transactionID = this._data.transactionID
  const request = {
    "TranId" : String(transactionID)
  }
  this._fuseSplashScreenService.show();
  this._reportsService.GetCambridgePaymentOrderDetails(request).subscribe((res:any)=> {
    if(res){
      this._fuseSplashScreenService.hide();
      this.cambridgePaymentDetails = res;
      this.dealDate = this.cambridgePaymentDetails.entryDate;
      this.dealNumber = this.cambridgePaymentDetails.ordNum;
      this.conversionCurrency = this.cambridgePaymentDetails.currency;
      this.currency =  this.cambridgePaymentDetails.settlement_currency;
      this.estimateCostCurrency = this.cambridgePaymentDetails.estimateCostCurrency;
      this.EstimateCostAmount = parseFloat(this.cambridgePaymentDetails.estimateCostAmount).toFixed(2);
      this.currencyRate = parseFloat(this.cambridgePaymentDetails.exchange).toFixed(2);
      this.serviceCharge = parseFloat(this.cambridgePaymentDetails.settlement_amount).toFixed(2);
      this.serviceChargeCurrency = this.cambridgePaymentDetails.settlement_currency;
      this.totalCost = parseFloat(this.cambridgePaymentDetails.amount).toFixed(2);
      this.depositAmount = parseFloat(this.cambridgePaymentDetails.buyAmount).toFixed(2);
      this.depositCurrency = this.cambridgePaymentDetails.buy;
      let a:any = (parseInt(this.totalCost)- parseInt(this.currencyRate)-parseInt(this.serviceCharge));
      this.withdrawlAmount = parseFloat(a).toFixed(2);
      this.SellCurrency = this.cambridgePaymentDetails.sell;
      this.SellAmount = this.cambridgePaymentDetails.sellAmount;
    }else{
      this._fuseSplashScreenService.hide();
      this._toastr.error('Error');
    }
  },
  error => {
    this._fuseSplashScreenService.hide();
    this._toastr.error(error, 'Error');
  });
  

}
  minimizeDialog() {
    let elements: any = document.getElementsByClassName("payout-receipt-details");
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.width = (this.hostWidth + 1 + 'px');
      elements[i].style.height = (this.hostHeight + 1 + 'px');
    }
    document.getElementById("sendpayoutdetail-content").style.maxHeight = "65vh";
    document.getElementById("sendpayoutdetail-wrapper").style.maxHeight = "85vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
  maximizeDialog() {
    let elements: any = document.getElementsByClassName("payout-receipt-details");
    for (var i = 0; i < elements.length; i++) {
      this.hostHeight = elements[i].clientHeight;
      this.hostWidth = elements[i].clientWidth;
      elements[i].style.width = ("100%");
      elements[i].style.height = ("100%");
    }
    document.getElementById("sendpayoutdetail-content").style.maxHeight = "100vh";
    document.getElementById("sendpayoutdetail-wrapper").style.maxHeight = "100vh";
    this.IsDialogMax = !this.IsDialogMax;
  }
}
