import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, ValidatorFn, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FuseSplashScreenService } from '../../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from '../../page.service';
import { CustomersService } from '../customers-service';
import { PointAccountsService } from '../../company-point-accounts/point-accounts.service';
const trimValidator: ValidatorFn = (control: FormControl) => {
  if(control.value){
      if (control.value.startsWith(' ')) {
        return {
          'trimError': { value: 'This field is required' }
        };
      }
  }
}

@Component({
  selector: 'app-customer-balance-adjustment',
  templateUrl: './customer-balance-adjustment.component.html',
  styleUrls: ['./customer-balance-adjustment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CustomerBalanceAdjustmentComponent implements OnInit {
  pointAccountForm: FormGroup;
  dialogTitle: string;
  action: string;
  userDetails: any;
  currencyCode;
  currencyList;
  pointAccountType;
  pointAccountTypeList;
  transactionTypeList;
  transactionType;
  Isdisabled: boolean = true
  constructor(
    public matDialogRef: MatDialogRef<CustomerBalanceAdjustmentComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _toastr: ToastrService,
    private _pageService: PageService,
    private _CustomersService: CustomersService,
    private _PointAccountsService: PointAccountsService
  ) {  // Set the defaults
    this.action = _data.action;
    
    // Set the private defaults
   
      this.dialogTitle = 'Adjust Point Balance';

    this.pointAccountForm = this._formBuilder.group({
   //   pointAccountType: ['',[Validators.required, trimValidator]],
      transactionType: ['',[Validators.required]],
      amount: ['',[Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
      reason: ['',[Validators.required, trimValidator]]
    });
    this.transactionTypeList = [{ transactionTypeName: 'Credit', id: 0 }, { transactionTypeName: 'Debit', id: 1 }];
  }

  ngOnInit() {
    if (localStorage.getItem('LoggedInUserData')) {
      this.userDetails = JSON.parse(localStorage.getItem('LoggedInUserData'));
      }
    this.getPointAccounts();
    
  }
  onSelectCurrency(event, value) {
    this.currencyCode = value.id;
  }
  onSelectPointAccountType(event, value) {
    this.pointAccountType = value.id;
  }
  onSelectTansactionType(event, value) {
    this.transactionType = value.id;
  }

//GetPointAccountType(){
//    this._CustomersService.GetPointAccountType().subscribe((res: any) => {
//      if (res.status == "Success") {
//        this.pointAccountTypeList = res.data;
//        console.log(this.pointAccountTypeList);
//      } else {
//        this._fuseSplashScreenService.hide();
//        this._toastr.error(res.errorDescription, 'Error');
//      }
//    });
//  }
  
      getPointAccounts() {
          const request = '?companyId=' + (this._data.data.companyID || this.userDetails.companyId);
          this._fuseSplashScreenService.show();
          this._PointAccountsService.GetCompanyPointDetail(request).subscribe((res: any) => {

              if (res.data) {
                  this._fuseSplashScreenService.hide();
                  this.pointAccountTypeList = res.data;
                  if(this.pointAccountTypeList && this.pointAccountTypeList.length > 1){
                    this.pointAccountForm.addControl('pointAccountIDValue', new FormControl('', Validators.required));
                  }
              } else {
                  this._fuseSplashScreenService.hide();
                  this._toastr.error('Error');
              }
          }, error => {
              this._fuseSplashScreenService.hide();
              this._toastr.error(error, 'Error');
          });
      }
    
  AdjustCustomerBalance() {  
    this.Isdisabled = false
    var form = this.pointAccountForm.getRawValue();
        if(!this.pointAccountForm.valid){
            this._toastr.error("Please add Balance Info", 'Error');
            return
        }
      const request = {
          "CompanyID": this._data.data.companyID ||this.userDetails.companyId,
          "CustomerID": this._data.data.id,
          "PointAccountID": (this.pointAccountTypeList && this.pointAccountTypeList.length > 1) ? this.pointAccountForm.get('pointAccountIDValue').value : this.pointAccountTypeList.length > 0 ? this.pointAccountTypeList[0].id : localStorage.getItem("pointAccountIDValue"),
          "TransactionType": this.pointAccountForm.get('transactionType').value,
          "RedeemType": 5,
          "Amount": this.pointAccountForm.get('amount').value,
          "Reason": this.pointAccountForm.get('reason').value
      };
      this._CustomersService.AdjustCustomerBalance(request).subscribe((res: any) => {
      if (res.status == "Success") {
        this.currencyList = res.data;
        this.matDialogRef.close(res.status);
        this._toastr.success('Amount adjusted successfully !');
      } else {
        this._fuseSplashScreenService.hide();
        this._toastr.error(res.message, 'Error');
      }
      this.Isdisabled = true
    });
  }
}
