import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, ValidatorFn, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FuseSplashScreenService } from '../../../../../@fuse/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { PageService } from '../../page.service';
import { CustomersService } from '../customers-service';
import { PointAccountsService } from '../../company-point-accounts/point-accounts.service';
const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value) {
    if (control.value.startsWith(' ')) {
      return {
        'trimError': { value: 'This field is required' }
      };
    }
  }
}

@Component({
  selector: 'app-customer-maxlimit',
  templateUrl: './customer-maxlimit.component.html',
  styleUrls: ['./customer-maxlimit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CustomerMaxLimitComponent implements OnInit {
  pointAccountForm: FormGroup;
  dialogTitle: string;
  action: string;
  userDetails: any;
  currencyCode;
  currencyList;
  pointAccountType;
  pointAccountTypeList;
  transactionTypeList;
  transactionType;
  Isdisabled: boolean = true;
  maxTransferLimit = '';
  constructor(
    public matDialogRef: MatDialogRef<CustomerMaxLimitComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _toastr: ToastrService,
    private _CustomersService: CustomersService,
  ) {  // Set the defaults
    this.action = _data.action;
    if(_data.data && (_data.data.maxTransferLimit || this._CustomersService.customerDetails)){
      this.maxTransferLimit = _data.data.maxTransferLimit || this._CustomersService.customerDetails.maxTransferLimit;
    }
    this.dialogTitle = 'Set Maximum Transfer Limit';

    this.pointAccountForm = this._formBuilder.group({
      amount: [this.maxTransferLimit, [Validators.required, Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
    });
  }

  ngOnInit() {
    if (localStorage.getItem('LoggedInUserData')) {
      this.userDetails = JSON.parse(localStorage.getItem('LoggedInUserData'));
    }

  }

  AdjustCustomerBalance() {
    if (!this.pointAccountForm.valid) {
      this._toastr.error("Please add Balance Info", 'Error');
      return
    }
    const request = {
      "customerId": this._data.data.id,
      "maxTransferLimit": this.pointAccountForm.get('amount').value
    };
    this._CustomersService.SetCustomerMaxLimit(request).subscribe((res: any) => {
      if (res.status == "Success" && res.data) {
        this.matDialogRef.close(res.status);
        this._toastr.success('limit set successfully !');
      } else {
        this._fuseSplashScreenService.hide();
        this._toastr.error(res.message, 'Error');
      }
    });
  }
}
