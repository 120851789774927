import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {RestService} from '../../../shared/rest.service';
@Injectable()
export class ProfileService
{
    onCountryChanged = new BehaviorSubject<any>([]);
    userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
    isAgentView: any = this.userDetails && this.userDetails.defaultAgentView;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _restService:RestService
    )
    {
        this.init();
    }
    init(){
        this.onCountryChanged = new BehaviorSubject<any>([]);
        this.userDetails = JSON.parse(localStorage.getItem("LoggedInUserData"));
        this.isAgentView = this.userDetails && this.userDetails.defaultAgentView;

    }
    /*
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    getProfile(){
        var _url="/Users/GetUsersDetails";
        return this._restService.Get(_url);
    }
    updateProfile(data){
        var _url="/Users/UpdateUser";
        return this._restService.Post(_url,data);
    }
    uploadImage(data){
        var _url="/Users/SetProfileImage";
        return this._restService.PostFile(_url,data);
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }
    removeValidators(form: FormGroup, formControl: any) {
        form.get(formControl).clearValidators();
        form.get(formControl).updateValueAndValidity();
    }
    addValidators(form: FormGroup, formControl: any) {
        form.get(formControl).setValidators([Validators.required, trimValidator]);
        form.get(formControl).updateValueAndValidity();
    }
    SetTwoWA(req) {
        var _url="/Users/SetTwoWA"
        return this._restService.Post(_url, req);
    }
}

const trimValidator: ValidatorFn = (control: FormControl) => {
    if (control && control.value && typeof control.value == "string" && !control.value.replace(/\s/g, '').length) {
        return {
            'trimError': { value: 'control has leading whitespace' }
        };
    }

    return null;
};
