import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AuthService } from 'app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../../shared/user.service';
import { RestService } from '../../../../../shared/rest.service';
import { ProfileService } from 'app/main/pages/profile/profile.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    userFullName:any;
    userEmail: any;
    companyName: any;
    url;
    role;
    userDetails;
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _userService : UserService,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _authService: AuthService,
        private _toastr: ToastrService,
        private _restService: RestService,
        private _ProfileService:ProfileService,
        private fuseSplashScreenService:FuseSplashScreenService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, {static: true})
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        // this._ProfileService.getProfile().subscribe((res: any) => {
        //      this.url = res.data.profileImage
        // })
    

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._fuseSidebarService.getSidebar('navbar') )
                    {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        // Get User Data
        if(localStorage.getItem('LoggedInUserData') !== null){
            this._userService.getActiveUser$().subscribe((data: any) => { 
                if(data!=null){
            this.userFullName = data.firstName;
            this.userEmail = data.email; 
            this.companyName = data.companyName;
            this.url = data.companyLogo;
            this.role= data.role
            }
        })
            this.userDetails = JSON.parse(
                localStorage.getItem("LoggedInUserData")
            );
        }

        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
    uploadImage(ev){
        this.fuseSplashScreenService.show();
        var file=ev.target.files[0];
        var formData=new FormData();
        formData.append('file',file);
        formData.append('companyId', this.userDetails.companyId);
        formData.append('companyKey',this.userDetails.companyKey);
        this._authService.setCompanyLogo(formData).subscribe(
            (res:any)=>{
                this.fuseSplashScreenService.hide();
                this.url= res.data;
                if(res.status.toLowerCase() == "failed"){
                    this._toastr.error("Error while updating profile",'Error'); 
                }else{
                    this._toastr.success("Company Logo updated successfully",'Success');
                }
                this._restService.GetUserProfile().subscribe(
                    (res: any) => {
                        this._authService.sendUserData(JSON.stringify(res.data));                       
                });  
            },() => {
                this.fuseSplashScreenService.hide();
            }
        )
    }

   
}
