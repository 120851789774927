import { Injectable } from '@angular/core';
import {RestService} from '../../../shared/rest.service';


@Injectable({
  providedIn: 'root'
})
export class PointAccountsService {
 
  constructor( private _restService:RestService
  ) { }

  SaveCompanyPointAccount(request: any) {
        var _url = "/CompanyPointAccount/SaveCompanyPointAccount";
        return this._restService.Post(_url, request);
  }

  DeleteCompanyPointAccount(deleteRequest: any) {
    var _url = "/api/CompanyPointAccount/DeleteCompanyPointAccount" + deleteRequest;
    return this._restService.Delete(_url);
    
  }

  UpdateCompanyPointAccount(updateRequest: any){
    var _url = "/CompanyPointAccount/UpdateCompanyPointAccount";
    return this._restService.Post(_url, updateRequest);
  }
  
  GetCompanyPointDetail(request: any) {
    var _url = '/CompanyPointAccount/GetCompanyPointAccounts' + request;
    return this._restService.Get(_url);
  }
  GetCurrencycode() {
    var _url = '/v1/Currencies';
    return this._restService.Get(_url);
  }
  GetPointAccountType() {
    var _url = '/PointAccountType/GetPointAccountType';
    return this._restService.Get(_url);
  }
}
