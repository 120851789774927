import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public CompanyList = [
    { companyName: 'Wakaya', description: 'this is wakaya', companyId: 1, provider: 'Exigo' },
    { companyName: 'TruVision', description: 'this is truvision', companyId: 2, provider: 'Disco' },
    { companyName: 'QSC', description: 'this is qsc', companyId: 3, provider: 'Exigo' }
  ];


  constructor() { }
}
