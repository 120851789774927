import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

@Component({
    selector: "fuse-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
    userDetails: any = JSON.parse(localStorage.getItem("LoggedInUserData")) || {};
    pageViews: any[] = [];
    @Input()
    layout = "vertical";

    @Input()
    navigation: any;

    @Input()
    Rolenavigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (localStorage.getItem("LoggedInUserData")) {
            this.userDetails = JSON.parse(
                localStorage.getItem("LoggedInUserData")
            );
        }

        // Load the navigation either from the input or from the service
        this.navigation =
            this.navigation ||
            this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation =
                    this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
            if(Object.keys(this.userDetails).length > 0){
                this.Rolenavigation = this.navigation.find(
                    (x) => x.role == this.userDetails.role
                );
                if (this.userDetails.role == 3) {
                    if (this.userDetails.permissions.length > 0) {
                        this.pageViews = [];
                        this.Rolenavigation.children.forEach(
                            (pageObj: any, pageIndex: number) => {
                                if (
                                    pageObj.type === "item" &&
                                    this.isPageExist(
                                        this.userDetails.permissions,
                                        pageObj.id
                                    )
                                ) {
                                    this.pageViews.push(pageObj);
                                } else if (pageObj.type === "collapsable") {
                                    let group: any = {};
                                    let groupChildren: any = [];
                                    pageObj.children.forEach(
                                        (groupObj: any, groupIndex: number) => {
                                            if (
                                                groupObj.type === "item" &&
                                                this.isPageExist(
                                                    this.userDetails.permissions,
                                                    groupObj.id
                                                )
                                            ) {
                                                groupChildren.push(groupObj);
                                            }
                                        }
                                    );
                                    if (groupChildren.length > 0) {
                                        group.id = pageObj.id;
                                        group.title = pageObj.title;
                                        group.type = pageObj.type;
                                        group.icon = pageObj.icon;
                                        group.children = groupChildren;
                                        this.pageViews.push(group);
                                    }
                                }
                            }
                        );
        
                        // this.Rolenavigation.children = pageViews;
                    } else {
                        this.pageViews = this.Rolenavigation.children;
                    }
                } else {
                    this.pageViews = this.Rolenavigation.children;
                }
            }
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
    isPageExist(arr: any, page: string) {
        return arr.some(function (el) {
            return el == page;
        });
    }
}
