import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateTime'})
export class DateTimePipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @returns {string}
     */
    transform(value: string): string
    {
        return value ? new Date(value).toLocaleTimeString() : '';
    }
}