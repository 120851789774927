// export const BASE_URL="https://api.mypaylink.xyz";
// export const BASE_URL = (location.hostname.search("adminportal")> -1) ? "https://api.paymenture.com" : (location.hostname.search("paymenture")> -1) ? "https://api.paymenture.com" : (location.hostname.search("mypaylink") > -1) ? "https://api.paymenture.com" : (location.hostname.search("rpmslive") > -1 ? "https://rpmsliveapi.wsicloud.net" : "https://rpmsapi.wsicloud.net");
export const isLiveEnv = (location.hostname.search("admin.paymenture")> -1 || location.hostname.search("mypaylink") > -1 || location.hostname.search("rpmslive") > -1) ? true  : false;
export const BASE_URL = isLiveEnv ? "https://api.paymenture.com" : "https://rpmsapi.wsicloud.net";
export const BASE_URL_FOR_BRANDING = isLiveEnv ? 'https://zippyapi.paymenture.com' : 'https://zippyapistage.paymenture.com' ;
export const API_URL = `${BASE_URL}/api`;
export const API_URL_FOR_BRANDING = `${BASE_URL_FOR_BRANDING}/api`;
export const API_ENDPOINTS={
    ACCOUNT:{
        USERS:`${API_URL}/Account/users`,
        ROLES:`${API_URL}/Account/roles`,
    },
    AUTH:`${BASE_URL}/Token`,
    PERMISSIONS:{
        PAGES:`${API_URL}/api/Permissions/Pages`
    }
}

export function objectToParams(object): string {
    // console.log("object", object);
    function clean(obj) {
        for (let propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
      }
      clean(object);
    return Object.keys(object).map((key) => (typeof (object[key]) === "object") ?
        subObjectToParams(encodeURIComponent(key), object[key]) :
        `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
    ).join('&');
}

function subObjectToParams(key, object): string {
    return Object.keys(object).map((childKey) => (typeof (object[childKey]) === "object") ?
        subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]) :
        `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`
    ).join('&');
}