import { Component ,Inject,OnInit,ViewEncapsulation} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'keyValuePairs'
})
export class KeyValuePairsPipe implements PipeTransform {
  transform(value: object): any[] {
    if (!value) return [];

    return Object.keys(value).map(key => ({ key, value: value[key] }));
  }
}
@Component({
  selector: 'app-difference-dialog',
  templateUrl: './difference-dialog.component.html',
  styleUrls: ['./difference-dialog.component.scss']
})
export class DifferenceDialogComponent implements OnInit {

  customClass:any;
    public confirmMessage: any;

    /**
     * Constructor
     *
     * @param {MatDialogRef<DifferenceDialogComponent>} dialogRef
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public dialogRef: MatDialogRef<DifferenceDialogComponent>
    )
    {
    }

  ngOnInit() {
    this.customClass =  this._data ? this._data.customClass: "";
    this.getKeyPairs(this.confirmMessage);
  }
  getKeyPairs(obj: object): { key: string, value: any }[] {
    if (!obj) return [];
    return Object.keys(obj).map(key => ({ key, value: obj[key] }));
  }

}
