import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DifferenceDialogComponent, KeyValuePairsPipe } from './difference-dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        DifferenceDialogComponent,KeyValuePairsPipe
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule
    ],
    entryComponents: [
        DifferenceDialogComponent
    ],
})
export class differenceDialogModule
{
}
