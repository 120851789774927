import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { objectToParams } from '../../../constants';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { RestService } from 'app/shared/rest.service';
import { navigation } from 'app/navigation/navigation';
import { AuthService } from 'app/shared/auth.service';
import { CustomTranslateService } from 'app/shared/custom-translate.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../shared/user.service';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomersService } from '../../../main/pages/customers/customers-service';
import { SharedService } from 'app/shared/share.service';
@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    isLogging: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userName: any;
    firstName: any;
    url;
    isLangMenu: boolean;
    companyList;
    userDetails: any;
    companyId;
    isImpersonate;
    PointAccounts;
    companyAccountid:any ="";
    searchPointAccountID:FormGroup;
    ReportType:any;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public CustomersService: CustomersService,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _customTranslateService: CustomTranslateService,
        private _router: Router,
        private _authService: AuthService,
        private _restService: RestService,
        private _userService: UserService,
        private _toastr: ToastrService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _formBuilder: FormBuilder,
        private sharedserive: SharedService,
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];


        // this.languages = [
        //     {
        //         id   : 'en',
        //         title: 'English',
        //         flag : 'us'
        //     },
        //     {
        //         id   : 'tr',
        //         title: 'Turkish',
        //         flag : 'tr'
        //     }
        // ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.searchPointAccountID = this._formBuilder.group({
            PointAccountIDValue:[],
          })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.ReportType = localStorage.getItem('ReportType');
        let customerData = JSON.parse(this._authService.getUserData());
        if(customerData && customerData.role !== 1){
            const request = '?companyId=' + customerData.companyId;
            this._fuseSplashScreenService.show();
            this.CustomersService.GetCompanyPointDetail(request).subscribe((res: any) => {
                if (res.data) {
                    this._fuseSplashScreenService.hide();
                    this.PointAccounts = res.data;
                    if(this.PointAccounts.length>0){
                        localStorage.setItem("pointAccountIDValue", this.PointAccounts[0].id);
    
                    }else{
                        localStorage.setItem("pointAccountIDValue", "5ef71e903901f313a0d34971");
                    }
                    if(this.companyAccountid ==""){
                        this.companyAccountid = localStorage.getItem("pointAccountIDValue");
                        this.searchPointAccountID.controls['PointAccountIDValue'].setValue(localStorage.getItem("pointAccountIDValue"));
                    }

                    this.sharedserive.pointAccountIDChanged.emit(this.companyAccountid);
                } else {
                    this._fuseSplashScreenService.hide();
                    this._toastr.error('Error');
                }
            }, error => {
                this._fuseSplashScreenService.hide();
                this._toastr.error(error, 'Error');
            });
        }
       
        // Subscribe to the config changes
        if (localStorage.getItem('LoggedInUserData')) {
            this.userDetails = JSON.parse(localStorage.getItem('LoggedInUserData'));
            if (this.userDetails && this.userDetails.role == 1) {
                this.GetCompanyList();
            }
        }
        if (localStorage.getItem('isImpersonate')) {
            this.isImpersonate = localStorage.getItem('isImpersonate');
        }
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        //check User
        if (localStorage.getItem('LoggedInUserData') !== null) {
            this._userService.getActiveUser$().subscribe((data: any) => {
                if (data != null) {
                    this.firstName = data.firstName;
                    this.url = data.profileImage;
                    this.userName = data.username;
                }
            });
        }

        //check Lang Menu
        this.isLangMenu = localStorage.getItem('LanguageMenuList') !== null;
        if (this.isLangMenu) {
            // Set the selected language from default languages 
            this.languages = JSON.parse(localStorage.getItem("LanguageMenuList"));
            var currentLang = this._translateService.currentLang;
            //var currentLang =  localStorage.getItem("DefaultLanguageCountryCode");
            var temp_obj = _.find(this.languages, function (t) { return t.Code == currentLang });
            if (temp_obj != undefined && temp_obj != null) {
                this.selectedLanguage = temp_obj;
            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    GetCompanyList() {
        this._restService.GetCompanyList().subscribe((res: any) => {
            if (res.data) {
                this.companyList = res.data;

            }
        })
    }
    backToAdmin() {
        this._authService.sendToken(JSON.parse(this._authService.getAdminToken()));
        this.userProfile(true, false);
    }
    backToCompany() {
        this._authService.sendToken(JSON.parse(this._authService.getCompanyToken()));
        this.userProfile(false, true);
    }
    userProfile(backToAdmin, backToCompany) {
        const self = this;
        self._restService.GetUserProfile().subscribe((res: any) => {
            this.isLogging = false;
            if (res.data.role != 4) {
                self._authService.sendUserData(JSON.stringify(res.data));
                //self._router.navigate(['pages/companies']);
                // self._router.navigate(['pages/dashboards/project']);
                self._toastr.success('Welcome' + " " + res.data.firstName + " " + '!');
                if (res.data.role == 1) {
                    localStorage.removeItem("isImpersonate");
                    localStorage.removeItem("superAdmin");
                }
                if (backToCompany) {
                    let backToUrl: string = self._authService.getCompanyBackToUrl();
                    localStorage.removeItem("LoggedInCompany");
                    localStorage.removeItem("CompanyBackToUrl");
                    // localStorage.removeItem("CompanyToken");
                    self._router.navigate([backToUrl]);
                }
                if (backToAdmin) {
                    let backToUrl: string = self._authService.getAdminBackToUrl();
                    localStorage.removeItem("LoggedInAdmin");
                    localStorage.removeItem("LoggedInCompany");
                    localStorage.removeItem("AdminBackToUrl");
                    localStorage.removeItem("CompanyBackToUrl");
                    localStorage.removeItem("CompanyToken");
                    self._router.navigate([backToUrl]);
                }
                setTimeout(() => {
                    window.location.reload();
                });
                // window.location.reload();
            }
            else {
                self._toastr.error('This User is Restricted to login!!', 'Error');
            }
            //  self._auth.sendDefaultCountryCode("1");
            // self._customTranslateService.GetLanguageCodeCombination("1",function(list: any){                

            // });
        },
            error => {
                this.isLogging = false;
                console.log(error);
            }
        )
    }
    Impersonate() {
        const self = this;
        if (this.userDetails.role == 1) {
            localStorage.setItem("superAdmin", this.userDetails.id);
        }
        // if (localStorage.getItem("isImpersonate")) {
        //     // this.companyId = (localStorage.getItem("superAdmin")).slice(1,-1);
        //     this.companyId = localStorage.getItem("superAdmin");
        // }
        var request = objectToParams({
            username: '',
            password: '',
            grant_type: (this.userDetails.role == 1) ? 'sso_token' : 'admin_token',
            client_id: this.companyId
        });
        this.isLogging = true;

        self._restService.Token(request).subscribe((result) => {
            if (result) {
                this._authService.sendToken(result);
                localStorage.setItem("isImpersonate", "1");
                // this.userProfile();
            }
        }, function (error) {
            self.isLogging = false;
            self._toastr.error(error.error.error_description, 'Error');
        })
    }
    onSelectChangeCompany(event, value) {
        this.companyId = value.id;
    }
    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    // setLanguage(lang): void {
    //     // Set the selected language for the toolbar
    //     this.selectedLanguage = lang;
    //     // Use the selected language for translations
    //     //this._translateService.use(lang.id);
    //     this._customTranslateService.SetTranslation(lang.Code);
    // }

    onLogOut() {
        this._authService.logout();
    }

    getPointAccountID(){
        this.companyAccountid = this.searchPointAccountID.get("PointAccountIDValue").value
        this.sharedserive.pointAccountIDChanged.emit(this.companyAccountid);
        localStorage.setItem("pointAccountIDValue", this.companyAccountid);
        const currentUrl = this._router.url;
        this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this._router.navigate([currentUrl]);
        });
      }
}
