import { Injectable } from '@angular/core';
import { BASE_URL, API_URL } from '../constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, forkJoin ,  of as observableOf} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from './rest.service';
import * as _ from 'lodash';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class CustomTranslateService {
  C_responseList: any;
  L_responseList: any;
  CL_responseList: any;
  CL_MenuList: any;
  constructor(private _http: HttpClient,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    private _restService: RestService,
    private _auth: AuthService) { }



  public GetAllCountries() {
    var _url = "/Country/GetAllCountries";
    return this._restService.Get(_url);
  }

  public GetAllLanguages() {
    var _url = "/Language/GetAllLanguages";
    return this._restService.Get(_url);
  }
  public GetAllCountryLanguages() {
    var _url = "/CountryLanguage/GetAllCompanyCountryLanguages";
    return this._restService.Get(_url);
  }
  public GetMultipleSources(): Observable<any[]> {
    let C_response = this.GetAllCountries();
    let L_response = this.GetAllLanguages();
    let CL_response = this.GetAllCountryLanguages();
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([C_response, L_response, CL_response]);
  }
  public GetLanguageCodeCombination(countryId:string,callBackUpdateUIElements: CallableFunction) {    
    //alert(countryId);
    this.CL_MenuList = [];
    this.GetMultipleSources().subscribe(responseList => {
      this.C_responseList = responseList[0].data;
      this.L_responseList = responseList[1].data;
      this.CL_responseList = responseList[2].data;
      this.CL_responseList.forEach((CL_obj: any) => {  
        if(CL_obj.countryId == parseInt(countryId)){
          var temp_list = _.find(this.L_responseList, function (t) { return t.id == CL_obj.languageId });
          if (temp_list != undefined && temp_list != null) {
            this.CL_MenuList.push({
              LanguageName: temp_list.name,
              Code: CL_obj.languageCode
            })
          }
        }    
      })
      //console.log(this.CL_MenuList);    
      this._auth.sendLanguageMenu(JSON.stringify(this.CL_MenuList));
      callBackUpdateUIElements(this.CL_MenuList);
    });    
  }

}
