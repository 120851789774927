import { Component, OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { CustomersService } from 'app/main/pages/customers/customers-service';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-resend-trigger',
    templateUrl: './resend-trigger.component.html',
    styleUrls: ['./resend-trigger.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResendTriggerComponent implements OnInit {
    dialogTitle: string;
    triggers = [
        {id:"CommissionPaid", name:"Last Commission Paid"},
        {id:"CardOrdered", name:"Last Card Ordered"},
        {id:"CardShipped", name:"Last Card Shipped"},
        {id:"ResetPassword", name:"Reset Password"},
        {id:"Enrollment", name:"eWallet Activation"}
    ];
    selectedTrigger = "CommissionPaid";
    constructor(
        public matDialogRef: MatDialogRef<ResendTriggerComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        private _fuseSplashScreenService: FuseSplashScreenService,
        public CustomersService: CustomersService,
        private _toastr: ToastrService
    ) {
        
        
    }

    ngOnInit() {
    }

    triggerUnifyEvent() {
        this._fuseSplashScreenService.show();
        var req = {
          "eventName":this.selectedTrigger,
          "externalCustomerID":this.CustomersService.customerDetails.externalCustomerID,
          "companyId":this.CustomersService.customerDetails.companyID
        }
        this.CustomersService.TriggerUnifyEvent(req).subscribe((res: any) => {
          this._fuseSplashScreenService.hide();                
          if(res.data || res.data == true){
            this._toastr.success("Successfully Resend", 'Success');
            this.matDialogRef.close()
          }else{
            this._toastr.error(res.message, 'Error');
          }
        }, error => {
          this._fuseSplashScreenService.hide();
          this._toastr.error(error, 'Error');
        });
      }
}